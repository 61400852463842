@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.airpark-scroll::-webkit-scrollbar {
    width: 2px;
    height: 10px;
    border-radius: 2px;
  }
  
  /* Track */
  .airpark-scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px #D1D1D1;
  }
   
  /* Handle */
  .airpark-scroll::-webkit-scrollbar-thumb {
    background: #33D085; 
    border-radius: 6px;
  }
  
  /* Handle on hover */
  .airpark-scroll::-webkit-scrollbar-thumb:hover {
    background: #17BB6C; 
  }